import { createAsyncThunk } from "@reduxjs/toolkit";
import { urlPath } from "../../../app/config/constants";
import { Requests } from "../../../app/httpRequest";
import { isAxiosError } from "../../../components/Utils/helper";
import { IServiceTypes } from "../Types/types";

const { businesses, profile, auth, notification } = urlPath;

export const getSupportedChannels = async (code: string) => {
  try {
    let res = await Requests.get(
      `/payout-requests/${code}/supported-channels`,
      null,
      null
    );
    return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return err;
  }
};

export const fetchMobileMoneyNetworks = async (query: { currency: string }) => {
  try {
    let res = await Requests.get(
      `/mobile-money/supported-networks`,
      null,
      query
    );
    if (res?.success) return Promise.resolve(res);
    return Promise.reject(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return err;
  }
};

export const updateBusinessInformation = createAsyncThunk(
  "business/update",
  async ({ id, data }: IServiceTypes, { rejectWithValue }) => {
    try {
      let res = await Requests.patchWithFormData(
        `${businesses}/${id}`,
        data,
        true
      );
      if (res?.success) {
        return Promise.resolve(res);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateBusinessContact = createAsyncThunk(
  "business/contact",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`business-contact`, data, null);
      return res;
    } catch (err) {
      console.log(err);
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      return rejectWithValue(err);
    }
  }
);

export const addBusinessOwner = createAsyncThunk(
  "business/owner",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`business-owners`, data, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const updateBusinessOwner = createAsyncThunk(
  "update/buiness-owner",
  async (
    { payload, id }: { payload: any; id?: string },
    { rejectWithValue }
  ) => {
    try {
      let res = await Requests.patch(`business-owners/${id}`, payload, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBusinessOwner = createAsyncThunk(
  "delete/buiness-owner",
  async (id: string, { rejectWithValue }) => {
    try {
      let res = await Requests.delete(`business-owners/${id}`, {}, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBusinessOwner = createAsyncThunk(
  "fetch/owner",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`business-owners`, null, null);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchAllIndustries = async () => {
  try {
    let res = await Requests.get(`industries`, null, null);
    return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return err;
  }
};

export const fetchAllBanks = async () => {
  try {
    let res = await Requests.get(`banks`, null, null);
    return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return err;
  }
};

export const resolveBankAccount = async (body: any) => {
  try {
    let res = await Requests.post(`banks/account-resolution`, body, null);
    return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response) return Promise.reject(err.response);
    return err;
  }
};

export const addBankAccount = createAsyncThunk(
  "business/account",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`bank-details`, data, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addKycBankAccount = createAsyncThunk(
  "kyc/add-bank-account",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`kyc/bank-details`, data, null);
      if (res?.status === "success" || res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBankAccount = createAsyncThunk(
  "profile/bank-account",
  async (_, { rejectWithValue }) => {
    try {
      // wallet kyc update ${profile}/payout-account ==> /kyc/bank-details
      let res = await Requests.get(`${profile}/payout-account`, null, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getKycBankAccount = createAsyncThunk(
  "kyc/bank-account",
  async (_, { rejectWithValue }) => {
    try {
      // wallet kyc update ${profile}/payout-account ==> /kyc/bank-details
      let res = await Requests.get(`kyc/bank-details`, null, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const removeBankAccount = createAsyncThunk(
  "business/account",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await Requests.delete(`bank-details/${id}`, {}, null);
      return res.status;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const addMobileMoneyAccount = createAsyncThunk(
  "mobile-money/add-account",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`mobile-money-details`, data, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeMobileMoneyAccount = createAsyncThunk(
  "mobile-money/remove-account",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await Requests.delete(`mobile-money-details/${id}`, {}, null);
      return res.status;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const updateBusinessKycDocs = createAsyncThunk(
  "business/document",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`documents`, data, null);
      if (res?.success) {
        return Promise.resolve(res);
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getDocument = createAsyncThunk(
  "business/get-document",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`documents`, null, null);
      if (res?.success) {
        return Promise.resolve(res);
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const kycUpdate = async () => {
  try {
    let res = await Requests.patch(`kyc`, {}, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const getBusinessKycStatus = async () => {
  try {
    let res = await Requests.get(`kyc`, null, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const getMobileMoneyAccount = createAsyncThunk(
  "mobile-money/mobile-money-account",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`/mobile-money-details`, null, null);
      if (res?.status === "success") {
        return res;
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getBusinessApiKey = createAsyncThunk(
  "business/api-key",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`${profile}/keys`, null, null);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const twoFactorActivation = async (data: any) => {
  try {
    let res = await Requests.post(`2FA/activate`, data, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const twoFactorDeactivation = async (data: any) => {
  try {
    let res = await Requests.post(`2FA/deactivate`, data, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const googleAuthenticator = async (data: { email: string }) => {
  try {
    let res = await Requests.post(`2FA/generate`, data, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const changePassword = async (data: any) => {
  try {
    let res = await Requests.post(`${auth}/change-password`, data, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const verifyTokenBeforeAction = createAsyncThunk(
  "2FA/verify-token",
  async (
    { data, isEnabled = false }: { data: any; isEnabled: boolean },
    { rejectWithValue }
  ) => {
    try {
      let res = await Requests.post(`2FA/verify`, data, null);

      if (res?.success) {
        return {
          ...res,
          isEnabled: isEnabled,
        };
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const readNotification = createAsyncThunk(
  "global/readNotification",
  async (data: any, { rejectWithValue }) => {
    try {
      await Requests.patch(`${notification}/${data.uuid}`, {}, null);
      return data.index;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const requestTokenBeforeAction = createAsyncThunk(
  "2FA/request-token",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`2FA/token`, {}, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendWithdrawalRequestToMail = createAsyncThunk(
  "2FA/payout",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`2FA/payout`, data, null);
      if (res?.success) {
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "profile/update",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.patch(`${profile}`, data, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getSupportedWallet = createAsyncThunk(
  "wallet/supported",
  async (id, { rejectWithValue }) => {
    try {
      let res = await Requests.get(
        `${businesses}/${id}/supported-cryptocurrencies`,
        null,
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const uploadDocuments = createAsyncThunk(
  "kyc/file",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.postFormData(`uploads`, data, true);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const generateBusinessApiKey = createAsyncThunk(
  "business/currency",
  async (data: { context: "LIVE" | "TEST" }, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`${businesses}/apikeys`, data, null);
      return {
        ...res,
        context: data.context,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWebhookEvents = createAsyncThunk(
  "webhook/events",
  async (_, { rejectWithValue }) => {
    try {
      const eventsResponse = await Requests.get(`webhooks/events`, null, null);
      return eventsResponse;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createWebhookEvents = async (data: any) => {
  try {
    const eventsResponse = await Requests.post(`webhooks`, data, null);
    return Promise.resolve(eventsResponse);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const updateWebhookEvents = async (id: string, data: any) => {
  try {
    const updatedResponse = await Requests.patch(`webhooks/${id}`, data, null);
    return Promise.resolve(updatedResponse);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const getWebhook = createAsyncThunk(
  "webhook/webhook",
  async (params: any, { rejectWithValue }) => {
    try {
      const eventsResponse = await Requests.get(`webhooks`, null, params);
      return {
        ...eventsResponse,
        env: params.environment,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
