import { Avatar, Dropdown, Menu } from "antd";
import Profile from "../Icons/Profile";
import ProfileSettings from "../Icons/ProfileSettings";
import { NavLink } from "react-router-dom";
import Logout from "../Icons/Logout";
import DropdownIcon from "../Icons/DropdownIcon";
import { destoryAuthUser } from "../../modules/Auth/store/authSlice";
import { IUserTypes } from "../../@types/common-type";
import { useAppDispatch } from "../../app/hooks";

interface IPropsProfileMenu {
  profile: IUserTypes;
}

export default function ProfileDropdown({ profile }: IPropsProfileMenu) {
  const dispatch = useAppDispatch();

  function logout() {
    dispatch(destoryAuthUser());
  }

  const menu = (
    <Menu className="border-r-0 menu" theme="light">
      <Menu.Item key="emailDropdown">
        <NavLink to="/">
          <div className="flex gap-4 items-center">
            <div>
              <Avatar className="uppercase">
                {profile?.firstName?.substring(0, 2)}
              </Avatar>
            </div>
            <div>
              <p className="text-[#000000] capitalize text-lg">
                {profile?.firstName}
              </p>
              <span className="-mt-6">{profile?.email}</span>
            </div>
          </div>
        </NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="Profile" icon={<Profile />}>
        <NavLink to="/settings">
          <p>Profile</p>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="Settings" icon={<ProfileSettings />}>
        <NavLink key={"settings"} state={{ key: "5" }} to="/settings">
          <p>Settings</p>
        </NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="Logout" icon={<Logout />} onClick={(e) => logout()}>
        <button>
          <p className="text-[#E31D1C]">Logout</p>
        </button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomLeft">
        <div>
          <Avatar className="uppercase">
            {profile?.firstName?.substring(0, 2)}
          </Avatar>

          <DropdownIcon className="ml-1" />
        </div>
      </Dropdown>
    </>
  );
}
