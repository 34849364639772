import { Modal } from "antd";
import { CSSProperties, ReactNode } from "react";
import CloseableIcon from "../Icons/CloseableIcon";

type IModalShellProps = {
  show: boolean;
  cancel: () => void;
  children: JSX.Element;
  closable?: boolean;
  CloseIcon?: JSX.Element;
  footer?: string | ReactNode;
  className?: string;
  style?: CSSProperties;
  title?: any;
  width?: number;
};
const ModalShell = ({
  show = false,
  cancel,
  children,
  className = "",
  style = {},
  CloseIcon = <CloseableIcon key={null} type={undefined} props={undefined} />,
  title = "",
  footer = null,
  width = 430,
}: IModalShellProps) => {
  return (
    <Modal
      visible={show}
      width={width}
      onCancel={cancel}
      style={style}
      closeIcon={CloseIcon}
      title={title}
      className={className}
      bodyStyle={style}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export default ModalShell;
