import Icon from "@ant-design/icons";

const GetStartedSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      version="1.1">
      <g id="surface1">
        <path
          stroke="none"
          fillRule="nonzero"
          fill="rgb(100%,100%,100%)"
          fillOpacity={1}
          d="M 2.5 0 C 1.128906 0 0 1.230469 0 2.726562 L 0 9.089844 C 0 15.105469 4.484375 20 10 20 C 15.515625 20 20 15.105469 20 9.089844 L 20 2.726562 C 20 1.230469 18.871094 0 17.5 0 Z M 2.5 1.816406 L 17.5 1.816406 C 17.972656 1.816406 18.332031 2.210938 18.332031 2.726562 L 18.332031 9.089844 C 18.332031 14.121094 14.613281 18.183594 10 18.183594 C 5.386719 18.183594 1.667969 14.121094 1.667969 9.089844 L 1.667969 2.726562 C 1.667969 2.210938 2.027344 1.816406 2.5 1.816406 Z M 5.546875 5.824219 C 5.226562 5.824219 4.90625 5.984375 4.660156 6.25 C 4.546875 6.375 4.453125 6.523438 4.390625 6.6875 C 4.328125 6.847656 4.296875 7.023438 4.296875 7.203125 C 4.296875 7.378906 4.328125 7.554688 4.390625 7.71875 C 4.453125 7.882812 4.546875 8.03125 4.660156 8.152344 L 9.167969 13.066406 C 9.402344 13.324219 9.71875 13.46875 10.050781 13.46875 C 10.382812 13.46875 10.703125 13.324219 10.9375 13.066406 L 15.339844 8.296875 C 15.574219 8.039062 15.703125 7.691406 15.703125 7.328125 C 15.703125 6.96875 15.574219 6.621094 15.339844 6.363281 C 15.101562 6.109375 14.785156 5.964844 14.453125 5.964844 C 14.121094 5.964844 13.800781 6.109375 13.566406 6.363281 L 10.050781 10.199219 L 6.433594 6.25 C 6.1875 5.984375 5.867188 5.824219 5.546875 5.824219 Z M 5.546875 5.824219 "
        />
      </g>
    </svg>
  );
};

const GetStartedIcon = (props: any) => (
  <Icon component={GetStartedSvg} {...props} />
);

export default GetStartedIcon;
