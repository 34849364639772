import dashboardReducer from "../modules/Dashboard/store/dashboardSlice";
import authReducer from "../modules/Auth/store/authSlice";
import { combineReducers } from "@reduxjs/toolkit";
import settingReducer from "../modules/Settings/store/settingSlice";
import globalStore from "./global/globalStore";

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  auth: authReducer,
  setting: settingReducer,
  globalState: globalStore,
});

export default rootReducer;
