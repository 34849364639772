import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { urlPath } from "../../../app/config/constants";
import { setUserToken } from "../../../app/config/storage";
import { Requests } from "../../../app/httpRequest";
import {
  hasErrorResponse,
  isAxiosError,
} from "../../../components/Utils/helper";

const { auth, notification } = urlPath;

export const registerUser = createAsyncThunk(
  "auth/signup",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.postWithoutHeader(
        `${auth}/register`,
        data,
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const switchActiveNotification = createAsyncThunk(
  "auth/viewNotifications",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.patch(`${notification}`, {}, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/user",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/${auth}/login`,
        data
      );
      if (res.data.success) {
        setUserToken(res.data.data.token);
        return res.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (err) {
      if (hasErrorResponse(err)) {
        if (!err.response) {
          throw err;
        }
        return rejectWithValue(err.response.data);
      }
    }
  }
);

export const fetchCountries = async () => {
  try {
    let res = await Requests.get(`countries`, null, null);
    return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    throw err;
  }
};

export const forgotPassword = createAsyncThunk(
  "auth/forgot",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.postWithoutHeader(
        `${auth}/forgot-password`,
        data,
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  "auth/resend",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.postWithoutHeader(
        `${auth}/verification/resend`,
        data,
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const verifyToken = createAsyncThunk(
  "auth/token",
  async (query: any, { rejectWithValue }) => {
    try {
      let res = await Requests.postWithoutHeader(
        `${auth}/verification`,
        {},
        query
      );
      if (res?.success) {
        setUserToken(res.data.token);
        return res;
      }
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const resetPassword = async (data: any) => {
  try {
    let res = await Requests.postWithoutHeader(
      `auth/reset-password`,
      data,
      null
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject({
        status: err,
        message: err.response.data,
      });
    return Promise.reject(err);
  }
};
export const verifyTwoFactorAuthentication = createAsyncThunk(
  "auth/2FAtoken",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.postWithoutHeader(
        `${auth}/2FA/verify`,
        data,
        null
      );
      if (res?.success) {
        setUserToken(res.data.token);
        return res;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resendAuthorizationToken = async (data: { email: string }) => {
  try {
    let res = await Requests.post(`2FA/resend`, data, null);
    if (res?.success) {
      return Promise.resolve(res);
    }
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};
