import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addBankAccount,
  getBankAccount,
  getKycBankAccount,
  addMobileMoneyAccount,
  getMobileMoneyAccount,
  updateBusinessContact,
  updateBusinessInformation,
  updateBusinessKycDocs,
  getBusinessApiKey,
  generateBusinessApiKey,
  addKycBankAccount,
} from "./service";
import { IApiResponseKey, IKycType, SettingState } from "../Types/types";

const initialState = {
  current: 0,
  businessForm: {
    registrationType: "",
    businessType: "",
    ownerShip: "",
    logoImage: null,
  },
  apiKey: {
    TEST: {
      secret: "",
      public: "",
    },
    LIVE: {
      secret: "",
      public: "",
    },
  },
  kycBank: null,
  countries: [],
  bankAccount: [],
  mobileMoney: [],
  loading: false,
} as SettingState;

const settingReducer = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    prevForm: (state) => {
      state.current--;
    },
    addToForm: (state, action: PayloadAction<IKycType>) => {
      state.businessForm = action.payload;
    },
    resetSteps: (state) => {
      state.current = 0;
    },
    nextForm: (state) => {
      state.current++;
    },
    saveCountryState: (state, { payload }) => {
      state.countries = payload;
    },
  },

  extraReducers: (builder) => {
    //kyc update
    builder.addCase(updateBusinessInformation.fulfilled, (state) => {
      state.current++;
      state.loading = false;
    });
    builder.addCase(updateBusinessInformation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBusinessInformation.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBusinessContact.fulfilled, (state) => {
      state.current++;
      state.loading = false;
    });
    builder.addCase(updateBusinessContact.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBusinessContact.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateBusinessKycDocs.fulfilled, (state) => {
      state.loading = false;
      state.current++;
    });
    builder.addCase(updateBusinessKycDocs.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBusinessKycDocs.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addBankAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addBankAccount.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addBankAccount.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(addKycBankAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addKycBankAccount.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addKycBankAccount.pending, (state) => {
      state.loading = true;
    });

    /* mobile money */
    builder.addCase(addMobileMoneyAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addMobileMoneyAccount.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addMobileMoneyAccount.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getMobileMoneyAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.mobileMoney = payload?.data;
    });
    builder.addCase(getMobileMoneyAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMobileMoneyAccount.rejected, (state) => {
      state.loading = false;
    });

    /* apikeys and bank-account */
    builder.addCase(getBankAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.bankAccount = payload?.data;
    });
    builder.addCase(getBankAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBankAccount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getKycBankAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.kycBank = payload?.data;
    });
    builder.addCase(getKycBankAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKycBankAccount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBusinessApiKey.fulfilled, (state, { payload }) => {
      const { data } = payload;
      data.map((item: IApiResponseKey) => {
        return item.context === "LIVE"
          ? (state.apiKey.LIVE[item.type] = item.value)
          : (state.apiKey.TEST[item.type] = item.value);
      });
    });
    builder.addCase(generateBusinessApiKey.fulfilled, (state, { payload }) => {
      const { data } = payload;
      const context: "LIVE" | "TEST" = payload.context;
      state.apiKey[context] = {
        secret: data.secretKey.value,
        public: data.publicKey.value,
      };
    });
  },
});

export default settingReducer.reducer;
export const { addToForm, prevForm, nextForm, saveCountryState, resetSteps } =
  settingReducer.actions;
