import ModalShell from "./ModalShell";
import AddButton from "../Button/AddButton";
import CancelButton from "../Button/CancelButton";
import { useAppDispatch } from "../../app/hooks";
import {
  getSupportedAllCurrency,
  getSupportedCurrency,
  updateWalletStatus,
} from "../../modules/Dashboard/store/service";
import { useState } from "react";
import { notification } from "antd";
import { changeWalletStatus } from "../../modules/Dashboard/store/dashboardSlice";

const SolanTerms = ({
  show = false,
  onCancel,
  currency,
  status,
  activeBusiness,
}: {
  show: boolean;
  onCancel: () => void;
  currency: string;
  status: "inactive" | "active";
  activeBusiness: string;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const coin = currency?.toUpperCase();

  async function changeSolanaStatus(value: string) {
    setLoading(true);
    let res = {
      status: value,
    };
    try {
      const response = await dispatch(
        updateWalletStatus({ data: res, currency: coin })
      ).unwrap();
      Promise.all([
        dispatch(
          changeWalletStatus({
            coin: coin,
            status: value,
          })
        ),
        dispatch(getSupportedCurrency(activeBusiness)).unwrap(),
        dispatch(getSupportedAllCurrency(activeBusiness)).unwrap(),
      ]);
      notification.success({
        message: response.message,
      });
    } catch (rejectedValueOrSerializedError: any) {
      notification.error({
        message:
          rejectedValueOrSerializedError?.message ||
          "Error, Could not update Wallet",
      });
    }
    setLoading(false);
  }

  return (
    <ModalShell
      title={"Business Wallet"}
      show={show}
      cancel={onCancel}
      className="transfer-modal">
      <div>
        <div className="flex flex-col items-center justify-center">
          {status === "inactive" ? (
            <div className="text-base text-[#2c3489]">
              <p>
                You are about to activate{" "}
                <span className="font-bold uppercase">{coin}</span>{" "}
                {coin === "SOL" && "(Solana)"} as a crypto payment option for
                your customers.
              </p>
              {coin === "SOL" && (
                <p className="my-5">
                  {" "}
                  Kindly note that Solana is not a stablecoin and it’s value
                  changes based on market price.
                </p>
              )}
            </div>
          ) : (
            <div className="text-base text-[#2c3489]">
              <p>
                You are about to deactivate{" "}
                <span className="font-bold uppercase">{coin}</span>{" "}
                {coin === "SOL" && "(Solana)"} as a crypto payment option for
                your customers.
              </p>{" "}
              <p className="my-5">
                {" "}
                Kindly note that your business will no longer be able to accept
                payments in <b>{coin === "SOL" ? "Solana" : coin}</b>.
              </p>{" "}
            </div>
          )}
        </div>
        <p className="text-[#2c3489] text-right text-base">
          Do you still want to proceed?
        </p>

        <div className="flex justify-end gap-2 pt-10">
          <div className="w-24">
            <CancelButton onClick={onCancel}>No</CancelButton>
          </div>
          <div className="w-24">
            {status === "inactive" ? (
              <AddButton
                loading={loading}
                onClick={() => changeSolanaStatus("active")}
                type="submit">
                Yes
              </AddButton>
            ) : (
              <AddButton
                loading={loading}
                onClick={() => changeSolanaStatus("inactive")}
                type="submit">
                Yes
              </AddButton>
            )}
          </div>
        </div>
      </div>
    </ModalShell>
  );
};
export default SolanTerms;
