import { useEffect, useMemo, useState } from "react";
import { Layout, notification, Spin, Switch } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import ProfileDropdown from "../Model/ProfileDropdown";
import Notifications from "../Model/Notification";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Skeleton from "react-loading-skeleton";
import { getNotification } from "../../modules/Dashboard/store/service";
import { switchBusinessEnviroment } from "../../modules/Dashboard/store/service";
//import { getFCMToken } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { eventTrack } from "../Utils/rudderstack";

const { Header } = Layout;

interface INavbarProps {
  collapsibleBar: () => void;
}
const Navbar = ({ collapsibleBar }: INavbarProps) => {
  const { user } = useAppSelector((s) => s.auth);
  const { business, isLoading, userProfile } = useAppSelector(
    (s) => s.dashboard
  );
  const { activeBusiness } = user;
  const context = business?.context || "TEST";
  const [loadingCheck, setLoadingCheck] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: notifications } = useAppSelector(
    (s) => s.globalState.notifications
  );

  async function onChange(checked: boolean) {
    //setChecked((prev) => !prev);
    setLoadingCheck(true);
    try {
      await switchBusinessEnviroment(activeBusiness, {
        environment: checked ? "LIVE" : "TEST",
      });
      eventTrack("account_created", {
        mode: checked ? "LIVE" : "TEST",
      });
      navigate("/get-started");
      window.location.reload();
    } catch (err: any) {
      notification.error({
        message: (err as Error).message,
        description: err.errors
          ? err.errors.map((details: string) => details)
          : "Error",
      });
    }
    setLoadingCheck(false);
  }

  useEffect(() => {
    async function fetchNotification() {
      try {
        await dispatch(getNotification()).unwrap();
      } catch (err: any) {
        notification.error({
          message: (err as Error).message,
          description:
            err.errors && err.errors.map((details: string) => details),
        });
      }
    }
    fetchNotification();
  }, [dispatch]);

  const notificationHoverList = useMemo(() => {
    return notifications.filter((x) => !x.read).slice(0, 3);
  }, [notifications]);

  /* const tesNotification = useCallback(async () => {
    try {
      await Promise.resolve(getFCMToken());
    } catch (err) {
      console.log(err);
    }
  }, []);*/

  return (
    <>
      <Header
        style={{ height: 70 }}
        className="site-layout-background bg-white md:border-b border-gray-200 border-opacity-70 border-solid 
        flex items-center justify-between mk-nav md:px-7 px-5 relative"
      >
        <div className="flex justify-between">
          <button className="lg:hidden" onClick={collapsibleBar}>
            <MenuOutlined />
          </button>
        </div>
        <div className="flex items-center ml-auto w-[17rem] justify-between">
          <Spin spinning={loadingCheck || isLoading}>
            <div className="flex gap-2 items-center">
              <Switch
                className={context === "LIVE" ? `bg-[green]` : `bg-[#CC9933]`}
                checked={context === "LIVE" ? true : false}
                onChange={onChange}
              />
              {isLoading ? (
                <Skeleton width={40} />
              ) : (
                <p className="capitalize">
                  {business?.context?.toLowerCase()} mode
                </p>
              )}
            </div>
          </Spin>
          <span className="relative flex ">
            <Notifications notifications={notificationHoverList} />
            {user?.activeNotification && (
              <span className="absolute left-3 w-2 h-2 bg-red-600 rounded-full"></span>
            )}
          </span>

          <div className="cursor-pointer spec grid items-center gap-1 grid-cols-1 lg:grid-cols-2">
            {isLoading ? (
              <Skeleton className="-mt-5" circle width={40} height={40} />
            ) : (
              <ProfileDropdown profile={userProfile} />
            )}
          </div>
        </div>
      </Header>
    </>
  );
};

export default Navbar;
