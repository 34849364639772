import { Avatar, Dropdown, Menu, notification } from "antd";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { IBusinessType } from "../../@types/common-type";
import { productImageUrl } from "../../app/config/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import gallery from "../../assets/svg-icon/gallery.svg";
import { switchActiveBusinessId } from "../../modules/Dashboard/store/service";
import DropdownIcon from "../Icons/DropdownIcon";
import UnionIcon from "../Icons/Union";
import { eventTrack } from "../Utils/rudderstack";
import NewBusinessModal from "./NewBusinessModal";

interface ISidebarProfile {
  activeBusiness: any;
  isLoading: boolean;
  currentBusiness: any;
}

export default function SidebarProfileDropdown({
  activeBusiness,
  isLoading,
  currentBusiness,
}: ISidebarProfile) {
  const [showAddBusinessModal, setShowAddBusinessModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const businesses =
    useAppSelector((state) => state.dashboard.userProfile?.businesses) || [];
  const dispatch = useAppDispatch();

  function onClickAddBusiness() {
    eventTrack("add_business_clicked");
    setShowAddBusinessModal(true);
  }

  const switchCurrentBusiness = async (value: string) => {
    setLoading(true);
    try {
      await dispatch(switchActiveBusinessId(value)).unwrap();
      navigate("/");
    } catch (err: any) {
      notification.error({
        message: (err as Error).message,
        description:
          err?.errors && err?.errors?.map((details: string) => details),
      });
    }
    setLoading(false);
  };

  const listOfBusiness = businesses?.map(
    (business: IBusinessType) =>
      business.uuid !== activeBusiness && {
        label: (
          <button
            className="w-full text-left capitalize font-medium text-base hover:text-[#cc9933]"
            onClick={() => switchCurrentBusiness(business.uuid)}
          >
            {business.name}
          </button>
        ),
        key: business.uuid,
      }
  );

  listOfBusiness?.push({
    label: (
      <button
        className="font-medium flex items-center gap-1 text-base hover:text-[#cc9933]"
        onClick={onClickAddBusiness}
      >
        <UnionIcon />
        <p>Add a business</p>
      </button>
    ),
    key: "add-business",
  });
  const SidebarProfileDropdownItem = (
    <Menu
      className=" w-full border-0 left-0 -top-1 rounded-t-none rounded-b-[5px]"
      theme="light"
      mode="vertical"
      items={listOfBusiness}
      subMenuCloseDelay={5}
    />
  );

  return (
    <>
      <Dropdown
        overlay={SidebarProfileDropdownItem}
        className="z-[2000] bg-white w-full p-3 flex items-center"
        overlayClassName="overlay-dropdown"
        placement="bottomLeft"
        trigger={["click"]}
      >
        <div className=" flex gap-2 rounded-t-[5px] items-center w-full">
          {isLoading || loading ? (
            <Skeleton className="-mt-5" circle width={30} height={30} />
          ) : currentBusiness?.logoImagePath ? (
            <Avatar
              size={40}
              src={productImageUrl(currentBusiness?.logoImagePath)}
            />
          ) : (
            <div
              style={{ border: "1px solid #3640A0" }}
              className="rounded-2xl p-1"
            >
              <img src={gallery} alt="logo" width={30} height={30} />
            </div>
          )}
          <div className="flex flex-col w-full">
            <div className="flex items-center gap-4">
              <span
                style={{ lineHeight: 1 }}
                className="text-[#3640A0] capitalize cursor-pointer text-[16px] font-medium"
              >
                {isLoading || loading ? (
                  <Skeleton width={50} />
                ) : (
                  currentBusiness?.name
                )}
              </span>
              <button>
                <DropdownIcon className="cursor-pointer" />
              </button>
            </div>
          </div>
        </div>
      </Dropdown>

      {showAddBusinessModal && (
        <NewBusinessModal
          total={businesses.length}
          onCancel={() => setShowAddBusinessModal(false)}
          onSuccess={() => setShowAddBusinessModal(false)}
          show={showAddBusinessModal}
        />
      )}
    </>
  );
}
