import Icon from "@ant-design/icons";

const UnionSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 11.866 11.8662 15 8 15C4.13379 15 1 11.866 1 8C1 4.134 4.13379 1 8 1C11.8662 1 15 4.134 15 8ZM16 8C16 12.4183 12.4185 16 8 16C3.58154 16 0 12.4183 0 8C0 3.58173 3.58154 0 8 0C12.4185 0 16 3.58173 16 8ZM8.5 7.5V5H7.5V7.5H5V8.5H7.5V11H8.5V8.5H11V7.5H8.5Z"
        fill="#02084B"
      />
    </svg>
  );
};

const UnionIcon = (props: any) => <Icon component={UnionSvg} {...props} />;

export default UnionIcon;
