import { message } from "antd";
import { RcFile } from "antd/es/upload";
import { AxiosError } from "axios";
import moment from "moment";
import numeral from "numeral";

import { documentIDCategory } from "../../data";
import { ImapValueType } from "../../modules/Settings/Types/types";
import {
  IBusinessOwnerResponse,
  ISingleFee,
  RangedFee,
} from "../../@types/common-type";
import { ItemInvoice } from "../../@types/invoice-type";

export const getFixedValue = (currency: number, value: number) => {
  if (currency) {
    return currency.toFixed(value);
  }
  return currency;
};

export const CurrencyProperty = {
  USD: "USD",
  NGN: "NGN",
  ZAR: "ZAR",
  KES: "KES",
  GHS: "GHS",
};

export function isAxiosError(something: any): something is AxiosError {
  return something?.success === false;
}
export function hasErrorResponse(e: any): e is AxiosError {
  return e && e.response.data;
}
export function isRequestError(e: any): e is AxiosError {
  return e && e.response.data;
}

export const HumanFriendlyDateAndTime = (dateTime: string, format: any) => {
  if (!dateTime) return;
  let _dateTime;
  if (format) {
    _dateTime = moment(dateTime, format);
  } else {
    _dateTime = moment(dateTime);
  }
  return _dateTime.format("DD-MM-YYYY, hh:mma");
};

export const HumanFriendlyDate = (dateTime: string) => {
  return moment(dateTime).format("DD-MM-YYYY");
};

export const OnlyTime = (value: string) => moment(value).format("HH:mma");

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const dataURItoFile = (dataURI: any, fileName?: string) => {
  if (!dataURI || !fileName) return null;
  var byteString = atob(dataURI.split(",")[1]);

  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  var arrayBuffer = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  var dataView = new DataView(arrayBuffer);
  const result = new File([dataView], fileName, {
    type: mimeString,
  });
  return result;
};

export const currencyColor = (currency: string) => {
  if (currency === "USDT") return "#2C3489";
  if (currency === "USDC") return "#02441C";
  return "#2C3489";
};

export const calculateTotalFiatBalance = (
  fiatUSDC: number,
  fiatUSDT: number,
  fiatSOL: number
): number => fiatUSDT + fiatUSDC + fiatSOL;

export const FormatMoney = (
  value?: number,
  symbol?: string,
  useDecimals?: boolean
) => {
  //if (typeof value === "string") value = Number(value);
  let currency;
  switch (symbol) {
    case "ZAR":
      currency = "R";
      break;
    case "GHS":
      currency = "GH₵";
      break;
    case "KES":
      currency = "Ksh";
      break;
    default:
      currency = "₦";
      break;
  }

  return `${currency} ${numeral(value).format(
    useDecimals ? "0,0,0.00" : "0,0,0"
  )}`;
};

export const FormatCurrency = (
  amount: number | string,
  useDecimals?: boolean
) => {
  if (amount === 0 || !amount) return 0;
  if (typeof amount === "string") amount = Number(amount);

  return `${numeral(amount).format(useDecimals ? "0,0,0.00" : "0,0,0")}`;
};

export const FormatCryptoFee = (
  amount: number | string,
  useDecimals: boolean = true
) => {
  if (typeof amount === "string") amount = Number(amount);

  return `${numeral(amount).format("0,0,0.[000000]")}`;
};

export const checkForEmptyValue = (value: any) => {
  for (const key in value) {
    if (value[key] === null || value[key] === undefined || value[key] === "") {
      delete value[key];
    }
  }
  return value;
};

export const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const FormatCryptoCurrency = (str: number, local?: boolean) => {
  if (!str) return 0;
  const splitStr = str.toString().split(".");
  const numerator = +splitStr[0];

  if (splitStr.length === 1) return numerator.toLocaleString("en-US");

  const decimal = local ? splitStr[1].slice(0, 2) : splitStr[1].slice(0, 4);

  return numerator.toLocaleString("en-US") + "." + decimal;
};

export const linkAddressToSolanaExplorer = (
  environment: "TEST" | "LIVE",
  address: string
) =>
  environment === "LIVE"
    ? `https://explorer.solana.com/address/${address}`
    : `https://explorer.solana.com/address/${address}?cluster=devnet`;

export const linkHashToSolanaExplorer = (
  environment: "TEST" | "LIVE",
  txhash: string
) =>
  environment === "LIVE"
    ? `https://explorer.solana.com/tx/${txhash}`
    : `https://explorer.solana.com/tx/${txhash}?cluster=devnet`;

export const tagColor = (
  status: "cancelled" | "pending" | "delivered" | "success",
  isPayment?: boolean
) => {
  if (status === "pending" && isPayment) return "#1890ff";
  if (status === "cancelled") return "#F50100";
  if (status === "delivered" || status === "success") return "#00CA4E";
  if (status === "pending") return "#FBC03D";
  return "#1890ff";
};

export const mapCountryIdToVerificationId = (data: any) => {
  const country: ImapValueType = data?.nationality?.replaceAll(" ", "_");
  let idType = data?.idDocument;
  const mappedResult: any = documentIDCategory[country].find(
    (docs) => docs.value === idType
  );
  return {
    country,
    ...mappedResult,
  };
};

export function dateIsValid(dateStr: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
}

export const isAnyValueUndefinedNullEmpty = (arr: any[]): boolean => {
  return arr.some((obj) => {
    return Object.values(obj).some((value) => {
      return value === undefined || value === null || value === "";
    });
  });
};

export const validateForm = (formValue: any) => {
  for (const key in formValue) {
    const value = formValue[key];

    if (key === "email") {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!regex.test(value)) {
        return {
          valid: false,
          message: "Enter a valid email",
        };
      }
    }

    if (Array.isArray(value) && isAnyValueUndefinedNullEmpty(value)) {
      return {
        valid: false,
        message: "Item field cannot be empty",
      };
    }

    if (value === null || value === undefined || value === "")
      return {
        valid: false,
        message: `${key.charAt(0).toUpperCase() + key.slice(1)} is required`,
      };
  }
  return {
    valid: true,
    message: "",
  };
};

export const isObjectEmpty = (objectName: IBusinessOwnerResponse) =>
  Object.keys(objectName).length === 0;

export function removeLeadingZeros(number: string) {
  return number.replace(/^0+/, "");
}

export const calcTotal = (allValues: ItemInvoice[]) => {
  return allValues.reduce(
    (accumulator, item) =>
      accumulator + Number(item.quantity) * Number(item.cost),
    0
  );
};

export const formatItemValue = (items: ItemInvoice[]) => {
  return items.map((item: ItemInvoice) => {
    const { cost, description, itemName, quantity } = item;
    return {
      cost: cost ? cost.toString().replace(/,/g, "") : "",
      description,
      itemName,
      quantity: Number(quantity),
    };
  });
};

export const NumberFormatter = (value: string) => {
  return parseFloat(parseFloat(value).toFixed(2)).toLocaleString("en-US", {
    useGrouping: true,
  });
};

export function numberWithCommas(num: number | string, symbol?: string) {
  let currency;
  switch (symbol) {
    case "ZAR":
      currency = "R";
      break;
    case "GHS":
      currency = "GH₵";
      break;
    case "KES":
      currency = "Ksh";
      break;
    case "NGN":
      currency = "₦";
      break;
    case "USD":
      currency = "$";
      break;
    default:
      currency = "";
      break;
  }
  const numberString = num.toString();
  const parts = numberString.split(".");
  const integerPart = parts[0];
  if (parts.length > 1) {
    return `${currency} ${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${
      parts[1]
    }`;
  }

  return `${currency} ${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function resolvePayoutFeeRange(
  ranges: RangedFee[] | ISingleFee,
  amountInLocalFiat: number
): number {
  if (amountInLocalFiat === 0) return 0;

  if (Array.isArray(ranges)) {
    const sortedRanges = ranges.sort((a, b) => b.min - a.min);
    let currentRange;
    for (let i = 0; i < sortedRanges.length; i++) {
      currentRange = sortedRanges[i];

      if (amountInLocalFiat >= currentRange.min) {
        if (
          currentRange.max === null ||
          amountInLocalFiat <= currentRange.max
        ) {
          break;
        }
      }
    }
    return amountInLocalFiat - (currentRange?.value || 0);
  }

  return amountInLocalFiat - (ranges.cap || 0);
}

export function resolveCryptoPayoutFee(
  amountCharge: ISingleFee,
  amountInLocalFiat: number,
  amount: number,
  conversionRate: number
): number {
  if (amountInLocalFiat === 0) return 0;
  let fee = amountCharge.rate * amount;
  const fiatFee = fee * conversionRate;

  if (amountCharge.cap !== null && fiatFee > amountCharge.cap) {
    fee = (amountCharge.cap / amountInLocalFiat) * amount;
  }

  return amount - fee;
}
