import axios, { AxiosInstance } from "axios";
import { fetchUserToken, clearData } from "./config/storage";
import { IvoryPayUrl, REQUEST_TIMEOUT } from "./config/constants";
import { APIMethod } from "../@types/types";

const headers: any = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const setAuthorization = (): { Authorization: string } => ({
  Authorization: `Bearer ${fetchUserToken()}`,
});

const instance: AxiosInstance = axios.create({
  baseURL: IvoryPayUrl,
  headers,
  timeout: REQUEST_TIMEOUT,
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      clearData();
      window.location.reload();
      return Promise.reject({
        status: error.response.status,
        message: "Session expired, please login again",
      });
    }
    /*if (error.response && error.response.status === 403) {
      clearData();
      window.location.reload();
      return Promise.reject({
        status: error.response.status,
        message: "Please login again",
      });
    }*/

    return Promise.reject(
      error
        ? error.response
          ? error.response.data
          : error.response
        : "Something went wrong"
    );
  }
);

const unauthorizedRequest = async (
  method: APIMethod,
  url: string,
  data: any,
  params: any
) => {
  const response = await instance.request({
    method,
    url,
    data,
    params,
    headers,
  });
  return response;
};

const authorizedRequest = async (
  method: APIMethod,
  url: string,
  data: any,
  params: any
) => {
  const response = await instance.request({
    method,
    url: url,
    data,
    params,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};

const authorizedRequestFormData = async (
  method: APIMethod,
  url: string,
  data: any,
  authorized: boolean
) => {
  let formData = new FormData();

  let headers: any = {
    "Content-Type": "multipart/form-data",
  };

  if (authorized) {
    headers = { ...headers, ...setAuthorization() };
  }

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let fieldData: any = data[key];

      if (Array.isArray(fieldData)) {
        fieldData.forEach((element) => {
          formData.append(key, element);
        });
        continue;
      }
      formData.append(key, fieldData);
    }
  }

  const response = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });
  return response;
};

const blobAuthorizedRequest = async (
  method: APIMethod,
  url: string,
  responseType: any,
  data: any = {}
) => {
  const response = await instance.request({
    method,
    url: url,
    responseType,
    data,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};

const requestWithFormData = async (
  method: APIMethod,
  url: string,
  data: any,
  authorized: boolean,
  autoAppend: boolean = true
) => {
  let formData = new FormData();

  let headers: any = {
    "Content-Type": "multipart/form-data",
  };
  if (autoAppend) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let fieldData: any = data[key];

        if (Array.isArray(fieldData)) {
          fieldData.forEach((element) => {
            formData.append(key, element);
          });
          continue;
        }
        formData.append(key, fieldData);
      }
    }
  }

  if (authorized) {
    headers = { ...headers, ...setAuthorization() };
  }

  const response = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
};

export const Requests = {
  get: (url: string, data: any, params: any): Promise<any> =>
    authorizedRequest("GET", url, data, params),

  post: (url: string, data: any, params: any): Promise<any> =>
    authorizedRequest("POST", url, data, params),

  getBlob: (url: string, data: any, responseType: any): Promise<any> =>
    blobAuthorizedRequest("GET", url, responseType, data),

  postWithoutHeader: (url: string, data: any, params: any): Promise<any> =>
    unauthorizedRequest("POST", url, data, params),

  put: (url: string, data: any, params: any): Promise<any> =>
    authorizedRequest("PUT", url, data, params),

  putWithoutHeader: async (url: string, data: any, params: any): Promise<any> =>
    unauthorizedRequest("PUT", url, data, params),

  postFormData: (
    url: string,
    data: any,
    authorized: boolean,
    autoAppend: boolean = true
  ): Promise<any> =>
    requestWithFormData("POST", url, data, authorized, autoAppend),

  delete: (url: string, data: any, params: any): Promise<any> =>
    authorizedRequest("DELETE", url, data, params),

  patch: (url: string, data: any, params: any): Promise<any> =>
    authorizedRequest("PATCH", url, data, params),

  patchWithFormData: (
    url: string,
    data: any,
    authorized: boolean
  ): Promise<any> => authorizedRequestFormData("PATCH", url, data, authorized),
};
