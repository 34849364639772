import { Layout, Menu } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

import { CloseOutlined } from "@ant-design/icons";
import { AiOutlineWallet } from "react-icons/ai";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { showSolanaTerms } from "../../modules/Dashboard/store/dashboardSlice";
import ActivityLog from "../Icons/ActivityLog";
import Analytics from "../Icons/Analysis";
import Barcode from "../Icons/Barcode";
import Chart from "../Icons/Chart";
import ConnectUser from "../Icons/Connect";
import Dashboard from "../Icons/Dashboard";
import GetStartedIcon from "../Icons/GetStarted";
import DeFiIcon from "../Icons/DeFi";
import InvoiceIcon from "../Icons/InvoiceIcon";
import IvoryStore from "../Icons/IvoryStore";
import PaymentLink from "../Icons/Link";
import NFTStore from "../Icons/NFTStore";
import Payout from "../Icons/Payout";
import Settings from "../Icons/Settings";
import StackIcon from "../Icons/Stacking";
import SwapIcon from "../Icons/Swap";
import TaxIcon from "../Icons/Tax";
import Transfer from "../Icons/Transfer";
import SidebarProfileDoropdown from "../Model/SiderProfileDropdown";
import SolanTerms from "../Model/SolanaTerms";
import ContentShell from "./ContentShell";
import Navbar from "./Navbar";
import { useWindowSize } from "react-use";
import { eventTrack } from "../Utils/rudderstack";
import GetStarted from "../../modules/GetStarted/GetStarted";

const { Sider } = Layout;

const { SubMenu } = Menu;

export function DashboardShell() {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [openKey, setOpenKey] = useState<string[]>([]);
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const { business, isLoading, showModal } = useAppSelector((s) => s.dashboard);
  const dispatch = useAppDispatch();
  const { activeBusiness } = useAppSelector(({ auth }) => auth.user);
  const width = useWindowSize().width;

  const setOpenKeyByLocation = useCallback(() => {
    let path = location.pathname;
    if (path.includes("get-started")) return setOpenKey(["get-started"]);
    if (path.includes("home")) return setOpenKey(["home"]);
    if (path.includes("analytics")) return setOpenKey(["analytics"]);
    if (path.includes("transactions")) return setOpenKey(["transaction"]);
    if (path.includes("wallet")) return setOpenKey(["wallet"]);
    if (path.includes("customers")) return setOpenKey(["customer"]);
    if (path.includes("payouts")) return setOpenKey(["payout"]);
    if (path.includes("qr-pay")) return setOpenKey(["qr-pay"]);
    if (path.includes("transfers")) return setOpenKey(["transfer"]);
    if (path.includes("payment-link")) return setOpenKey(["payment-link"]);
    if (path.includes("storefront")) return setOpenKey(["storefront"]);
    if (path.includes("settings")) return setOpenKey(["settings"]);
    if (path.includes("settings/verifybusiness"))
      return setOpenKey(["settings/verifybusiness"]);
    if (path.includes("swap")) return setOpenKey(["swap"]);
    if (path.includes("activity-logs")) return setOpenKey(["activity-log"]);
    if (path.includes("invoice")) return setOpenKey(["invoice"]);
    if (path.includes("virtual-account"))
      return setOpenKey(["virtual-account"]);
    //if (path.includes("stacking")) return setOpenKey(["stacking"]);
    //if (path.includes("calculator")) return setOpenKey(["calculator"]);

    return setOpenKey(["get-started"]);
  }, [location]);

  const close = useCallback((e: any) => {
    if (ref?.current?.contains(e.target)) {
      return;
    }
    setCollapsed(true);
  }, []);

  function handleMenu(event?: string) {
    if (event) {
      eventTrack(event, {
        currentBusiness: business?.name || "",
      });
    }
    setCollapsed(true);
  }

  useEffect(() => {
    if (width <= 1024) {
      document.addEventListener("mousedown", close);
    }
    return () => {
      document.removeEventListener("mousedown", close);
    };
  }, [width, close]);

  useEffect(() => {
    setOpenKeyByLocation();
  }, [setOpenKeyByLocation]);

  return (
    <Layout>
      <Sider className={`sidebar ${collapsed ? "" : "open"}`}>
        <div ref={ref}>
          <div className="flex items-center pr-[14px] pl-[10px] justify-center mt-5 mb-2 relative">
            <SidebarProfileDoropdown
              isLoading={isLoading}
              currentBusiness={business}
              activeBusiness={activeBusiness}
            />
            <button
              className="absolute lg:hidden -top-5 right-2"
              onClick={() => setCollapsed(true)}>
              <CloseOutlined style={{ color: "#fff" }} />
            </button>
          </div>
          <Menu
            className="border-r-0 menu"
            theme="light"
            mode="inline"
            defaultSelectedKeys={["home"]}
            selectedKeys={openKey}
            defaultOpenKeys={["1"]}>
            {" "}
            <SubMenu key="1" title="OVERVIEW" className="ivory-submenu">
              <Menu.Item key="get-started" icon={<GetStartedIcon />}>
                <NavLink
                  onClick={() => handleMenu("get_started_clicked")}
                  to="/get-started">
                  Get Started
                </NavLink>
              </Menu.Item>
              <Menu.Item key="home" icon={<Dashboard />}>
                <NavLink
                  onClick={() => handleMenu("dashboard_clicked")}
                  to="/home">
                  Dashboard
                </NavLink>
              </Menu.Item>
              <Menu.Item key="analytics" icon={<Analytics />}>
                <NavLink
                  onClick={() => handleMenu("analytics_clicked")}
                  to="/analytics">
                  Analytics
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="2" title="PAYMENTS" className="ivory-submenu">
              <Menu.Item key="transaction" icon={<Chart />}>
                <NavLink
                  onClick={() => handleMenu("transaction_clicked")}
                  to="/transactions">
                  Transactions
                </NavLink>
              </Menu.Item>

              <Menu.Item
                key="wallet"
                icon={<AiOutlineWallet className="text-white" size={20} />}>
                {/* Only for nigeria */}
                {business?.country?.code === "NG" ? (
                  <>
                    <NavLink
                      onClick={() => handleMenu("wallet_clicked")}
                      to="/wallet">
                      Wallets
                    </NavLink>
                  </>
                ) : (
                  <>
                    <p style={{ color: "#969ba0" }} className="-mb-5">
                      Wallets
                    </p>
                    <span className=" text-sm text-[#F1B948]">
                      (coming soon)
                    </span>
                  </>
                )}
              </Menu.Item>
              <Menu.Item key="customer" icon={<ConnectUser />}>
                <NavLink
                  onClick={() => handleMenu("customers_clicked")}
                  to="/customers">
                  Customers
                </NavLink>
              </Menu.Item>
              <Menu.Item key="payout" icon={<Payout />}>
                <NavLink
                  onClick={() => handleMenu("fiat_payouts_clicked")}
                  to="/payouts">
                  Fiat payouts
                </NavLink>
              </Menu.Item>
              <Menu.Item key="transfer" icon={<Transfer />}>
                <NavLink
                  onClick={() => handleMenu("crypto_payouts_clicked")}
                  to="/transfers">
                  Crypto payouts
                </NavLink>
              </Menu.Item>

              <Menu.Item
                className="relative"
                key="invoice"
                icon={<InvoiceIcon />}>
                <NavLink
                  onClick={() => handleMenu("invoice_clicked")}
                  to="/invoice">
                  Invoice
                </NavLink>
              </Menu.Item>
              {/*
              <Menu.Item
                className="relative"
                key="virtual-account"
                icon={
                  <BankOutlined
                    style={{
                      color: "#fff",
                    }}
                  />
                }
              >
                <NavLink to="/virtual-account">Virtual Account</NavLink>
              </Menu.Item>*/}
            </SubMenu>
            <SubMenu key="3" title="PRODUCTS" className="ivory-submenu">
              <Menu.Item key="payment-link" icon={<PaymentLink />}>
                <NavLink
                  onClick={() => handleMenu("payments_link_clicked")}
                  to="/payment-link">
                  Payment links
                </NavLink>
              </Menu.Item>
              <Menu.Item key="qr-pay" icon={<Barcode />}>
                <NavLink
                  onClick={() => handleMenu("qr_pay_clicked")}
                  to="/qr-pay">
                  QR pay
                </NavLink>
              </Menu.Item>
              <Menu.Item key="storefront" icon={<IvoryStore />}>
                <NavLink
                  onClick={() => handleMenu("ivory_storefront_clicked")}
                  to="/ivory-storefront">
                  Ivory storefront
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item
                key="NFT storefront"
                icon={<NFTStore />}
                disabled={true}>
                <NavLink onClick={() => handleMenu()} to="/nft-storefront">
                  <p style={{ color: "#969ba0" }} className="-mb-5">
                    NFT storefront
                  </p>
                  <span className="text-sm text-[#F1B948]">(coming soon)</span>
                </NavLink>
              </Menu.Item> */}
            </SubMenu>
            <SubMenu key="4" title="DEFI SERVICES" className="ivory-submenu">
              <Menu.Item key="swap" icon={<SwapIcon />}>
                <NavLink onClick={() => handleMenu("swaps_clicked")} to="/swap">
                  Swaps
                </NavLink>
              </Menu.Item>
              <Menu.Item key="defi" icon={<DeFiIcon />} disabled={true}>
                <NavLink onClick={() => handleMenu()} to="/#">
                  <p style={{ color: "#969ba0" }} className="-mb-5">
                    Defi Loans
                  </p>
                  <span className=" text-sm text-[#F1B948]">(coming soon)</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="staking" icon={<StackIcon />} disabled={true}>
                <NavLink onClick={() => handleMenu()} to="/#">
                  <p style={{ color: "#969ba0" }} className="-mb-5">
                    Staking
                  </p>
                  <span className=" text-sm text-[#F1B948]">(coming soon)</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="tax" icon={<TaxIcon />} disabled={true}>
                <NavLink onClick={() => handleMenu()} to="/#">
                  <p style={{ color: "#969ba0" }} className="-mb-5">
                    Tax calculator
                  </p>
                  <span className=" text-sm text-[#F1B948]">(coming soon)</span>
                </NavLink>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="5" title="ACCOUNT" className="ivory-submenu">
              <Menu.Item key="activity-log" icon={<ActivityLog />}>
                <NavLink onClick={() => handleMenu()} to="/activity-logs">
                  Activity logs
                </NavLink>
              </Menu.Item>
              <Menu.Item key="settings" icon={<Settings />}>
                <NavLink onClick={() => handleMenu()} to="/settings">
                  Settings
                </NavLink>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      </Sider>
      <Layout className="ivry-layout">
        <Navbar collapsibleBar={() => setCollapsed(false)} />

        <ContentShell className="ivory-site-layout ivry-bg">
          <Outlet />
        </ContentShell>
      </Layout>
      {showModal.show && (
        <SolanTerms
          show={showModal.show}
          currency={showModal.currency}
          status={showModal.status}
          activeBusiness={activeBusiness}
          onCancel={() =>
            dispatch(
              showSolanaTerms({
                show: false,
                currency: "",
                status: "inactive",
              })
            )
          }
        />
      )}
    </Layout>
  );
}
