import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationType } from "../../components/Model/types";
import {
  IBusinessOwnerResponse,
  IPropsSupportedCurrency,
  IPropsSupportedAllCurrency,
} from "../../@types/common-type";
import {
  getNotification,
  getSupportedAllCurrency,
  getSupportedCurrency,
  verifyGoogleAuthenticator,
} from "../../modules/Dashboard/store/service";
import {
  getBusinessOwner,
  addBusinessOwner,
  updateBusinessOwner,
  requestTokenBeforeAction,
  verifyTokenBeforeAction,
  readNotification,
  sendWithdrawalRequestToMail,
  getWebhookEvents,
  getWebhook,
} from "../../modules/Settings/store/service";
import { ITypeOwner } from "../../modules/Settings/Types/types";

const eventMap: { [key: string]: string } = {
  "transaction.success": "Successful Transaction",
  "transaction.failed": "Failed Transaction",
  "payoutRequest.success": "Successful Payout",
  "payoutRequest.failed": "Failed Payout",
  "payoutRequest.declined": "Declined Payout",
  "virtualAccountTransfer.success": "Virtual Account",
};

interface GlobalState {
  ownerStateForm: boolean;
  ownerInfo: IBusinessOwnerResponse | null;
  role: ITypeOwner;
  businessOwners: [];
  isSubmitting: boolean;
  showVerificationModal: boolean;
  showGoogleAuthenticator: boolean;
  notifications: { data: NotificationType[]; count: number };
  invoiceTab: string;
  totalNumberOfOwners: number;
  supportedCurrency: Array<IPropsSupportedCurrency>;
  supportedAllCurrency: Array<IPropsSupportedAllCurrency>;
  webhookEvents: [];
  webhookInfo: any;
}
const initialState = {
  ownerStateForm: false,
  ownerInfo: null,
  businessOwners: [],
  totalNumberOfOwners: 0,
  notifications: {
    data: [] as NotificationType[],
    count: 0,
  },
  isSubmitting: false,
  showVerificationModal: false,
  showGoogleAuthenticator: false,
  invoiceTab: "1",
  supportedCurrency: [
    {
      currency: "USDT",
    },
  ],
  supportedAllCurrency: [
    {
      currency: "USDT",
    },
  ],
  webhookEvents: [],
  webhookInfo: {
    TEST: {
      event: [],
      url: null,
      id: null,
    },
    LIVE: {
      event: [],
      url: null,
      id: null,
    },
  },
} as GlobalState;

const globalStateReducer = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    verificationModal: (state, { payload }) => {
      state.showVerificationModal = payload;
    },
    showGoogleAuthModal: (state, { payload }) => {
      state.showGoogleAuthenticator = payload;
    },
    changeOwnerFormStatus: (state, { payload }) => {
      state.ownerStateForm = payload.show;
      state.ownerInfo = payload.data;
      state.role = payload.role;
    },
    removeOwnerFromState: (state, { payload }) => {
      const index = state.businessOwners.findIndex(
        (res: IBusinessOwnerResponse) => res.uuid === payload
      );
      // @ts-ignore: for now
      state.businessOwners.splice(index, 1);
      state.totalNumberOfOwners--;
    },
    updateInvoiceTab: (state, { payload }) => {
      state.invoiceTab = payload;
    },
    updateWebhook: (state, { payload }) => {
      console.log(payload);
      state.webhookInfo[payload.env] = {
        id: payload.data.id,
        events: payload.data.events,
        url: payload.data.url,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessOwner.fulfilled, (state, { payload }) => {
      state.businessOwners = payload.data;
      state.totalNumberOfOwners = payload.data.length;
    });
    builder.addCase(addBusinessOwner.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(
      addBusinessOwner.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          data: IBusinessOwnerResponse;
        }>
      ) => {
        // @ts-ignore: for now
        state.businessOwners.push(payload.data);
        state.isSubmitting = false;
        state.ownerStateForm = false;
        state.totalNumberOfOwners++;
      }
    );

    builder.addCase(addBusinessOwner.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(updateBusinessOwner.rejected, (state) => {
      state.isSubmitting = false;
    });

    builder.addCase(updateBusinessOwner.pending, (state) => {
      state.isSubmitting = true;
    });

    builder.addCase(
      updateBusinessOwner.fulfilled,
      (
        state,
        {
          payload,
        }: PayloadAction<{
          data: IBusinessOwnerResponse;
        }>
      ) => {
        state.isSubmitting = false;
        state.ownerStateForm = false;

        const index = state.businessOwners.findIndex(
          (res: IBusinessOwnerResponse) => res.uuid === payload.data.uuid
        );
        // @ts-ignore: for now
        state.businessOwners.splice(index, 1, payload.data);
      }
    );
    builder.addCase(requestTokenBeforeAction.fulfilled, (state) => {
      state.showVerificationModal = true;
    });
    builder.addCase(sendWithdrawalRequestToMail.fulfilled, (state) => {
      state.showVerificationModal = true;
    });

    builder.addCase(verifyTokenBeforeAction.fulfilled, (state, { payload }) => {
      state.showVerificationModal = false;
      state.showGoogleAuthenticator = payload.isEnabled;
    });
    builder.addCase(readNotification.fulfilled, (state, { payload }) => {
      state.notifications.data[payload].read = true;
    });
    builder.addCase(verifyGoogleAuthenticator.fulfilled, (state) => {
      state.showGoogleAuthenticator = false;
    });
    builder.addCase(getNotification.fulfilled, (state, { payload }) => {
      state.notifications = {
        data: payload.data.records,
        count: payload.data.count,
      };
    });
    builder.addCase(getSupportedCurrency.fulfilled, (state, { payload }) => {
      state.supportedCurrency = payload.data;
    });
    builder.addCase(getSupportedAllCurrency.fulfilled, (state, { payload }) => {
      state.supportedAllCurrency = payload.data;
    });

    builder.addCase(getWebhookEvents.fulfilled, (state, { payload }) => {
      state.webhookEvents = payload.data
        ?.map((item: any) => {
          return {
            label: eventMap[item.name],
            name: item.name,
          };
        })
        .filter((event: any) => event.label !== undefined);
    });
    builder.addCase(getWebhook.fulfilled, (state, { payload }) => {
      state.webhookInfo[payload.env] = Object.assign(
        {},
        ...payload.data?.map((item: any) => {
          return {
            url: item.url,
            id: item.uuid,
            events: item.events,
          };
        })
      );
    });
  },
});

export default globalStateReducer.reducer;
export const {
  removeOwnerFromState,
  changeOwnerFormStatus,
  verificationModal,
  showGoogleAuthModal,
  updateInvoiceTab,
  updateWebhook,
} = globalStateReducer.actions;
