export const month = [
  { month: "JAN", value: "01" },
  { month: "FEB", value: "02" },
  { month: "MAR", value: "03" },
  { month: "APR", value: "04" },
  { month: "MAY", value: "05" },
  { month: "JUN", value: "06" },
  { month: "JUL", value: "07" },
  { month: "AUG", value: "08" },
  { month: "SEP", value: "09" },
  { month: "OCT", value: "10" },
  { month: "NOV", value: "11" },
  { month: "DEC", value: "12" },
];

export const monthUtlis = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const documentIDCategory = {
  Nigerian: [
    {
      value: "votersCard",
      name: "Voters Card",
      text: "Voters Card Number",
    },
    {
      value: "driversLicence",
      name: "Drivers License",
      text: "Drivers License Number",
    },
    {
      value: "interNationalPassport",
      name: "International Passport",
      text: "International Passport Number",
    },
    {
      value: "nimc",
      name: "NIMC",
      text: "National Identification Number",
    },
    {
      value: "nationalIdCard",
      name: "National ID",
      text: "National Identification Number",
    },
  ],
  Ghanaian: [
    {
      value: "votersCard",
      name: "Voters Card",
      text: "Voters Card Number",
    },
    {
      value: "driversLicence",
      name: "Drivers License",
      text: "Drivers License Number",
    },
    {
      value: "interNationalPassport",
      name: "International Passport",
      text: "International Passport Number",
    },
    {
      value: "ssnit",
      name: "SSNIT",
      text: "SSNIT Number",
    },
  ],
  Kenyan: [
    {
      value: "nationalIdCard",
      name: "National ID",
      text: "National Identification Number",
    },
  ],
  South_African: [
    {
      value: "nationalIdCard",
      name: "National ID",
      text: "National Identification Number",
    },
  ],
};
