import { Button } from "antd";
import { ButtonHTMLType } from "antd/lib/button/button";
import { CSSProperties } from "react";

interface ICancelProps {
  children: string;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  type?: ButtonHTMLType;
}

export default function CancelButton({
  children,
  className = "",
  onClick,
  loading = false,
  disabled = false,
  type,
}: ICancelProps) {
  return (
    <Button
      size="large"
      className={`${className} ivry-cancel-button w-full h-10 md:text-sm text-xs p-2 text-[#948D8D] bg-[transparent] rounded-md border-0 active:bg-[#2C3489]`}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      htmlType={type}
    >
      {children}
    </Button>
  );
}
