import "react-dropdown/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect } from "react";

import PageRoutes from "./components/Routes";
import { rudderInitialize } from "./components/Utils/rudderstack";

function App() {
  useEffect(() => {
    rudderInitialize();
  }, []);

  return (
    <>
      <PageRoutes />
    </>
  );
}

export default App;
