import * as rudderanalytics from "rudder-sdk-js";

export function rudderInitialize() {
  if (process.env.NODE_ENV === "production") {
    rudderanalytics.ready(() => {
      console.log("We are all set!!!");
    });
    rudderanalytics.load(
      `${process.env.REACT_APP_RUDDERSTACK_KEY}`,
      `${process.env.REACT_APP_RUDDERSTACK_DATAPLANE}`
    );
  }
}

export function eventIdentify(action, payload) {
  rudderanalytics.identify(action, payload);

  console.log("rudder identify call");
}

export function eventTrack(action, payload) {
  rudderanalytics.track(`${action}`, payload || null);
  console.log("rudder track call");
}

export function eventPage(pageName, properties = null) {
  rudderanalytics.page(pageName, properties);
}
