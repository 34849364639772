import { useEffect } from "react";
import { Layout } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getBusiness,
  getProfile,
  getSupportedAllCurrency,
  getSupportedCurrency,
} from "../../modules/Dashboard/store/service";
import { saveCountryState } from "../../modules/Settings/store/settingSlice";
import { fetchCountries } from "../../modules/Auth/store/service";
import {
  getWebhook,
  getWebhookEvents,
} from "../../modules/Settings/store/service";

const { Content } = Layout;

type ShellProps = {
  children?: JSX.Element | JSX.Element[];
  className?: String;
  subnav?: Boolean;
};

const ContentShell = ({ children, subnav, className }: ShellProps) => {
  const dispatch = useAppDispatch();
  const { activeBusiness } = useAppSelector(({ auth }) => auth.user);

  useEffect(() => {
    async function fetchBusinessAndProfile() {
      try {
        await Promise.all([
          dispatch(getBusiness(activeBusiness)).unwrap(),
          dispatch(getProfile()).unwrap(),
          dispatch(getSupportedCurrency(activeBusiness)).unwrap(),
          dispatch(getSupportedAllCurrency(activeBusiness)).unwrap(),
          dispatch(getWebhookEvents()).unwrap(),
          dispatch(getWebhook({ environment: "TEST" })).unwrap(),
          dispatch(getWebhook({ environment: "LIVE" })).unwrap(),
        ]);
      } catch (rejectedValueOrSerializedError: any) {
        console.log(rejectedValueOrSerializedError);
      }
    }
    fetchBusinessAndProfile();
  }, [dispatch, activeBusiness]);

  useEffect(() => {
    async function getData() {
      try {
        const countries = await fetchCountries();
        if (countries.success) dispatch(saveCountryState(countries.data));
      } catch (err: any) {
        console.log(err);
      }
    }
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content
      className={`app-content ${className ? className : ""}`}
      style={{
        margin: "16px 40px 0",
        overflow: "initial",
        marginTop: "16px",
      }}>
      {children}
    </Content>
  );
};

export default ContentShell;
