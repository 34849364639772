import { Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../app/hooks";
import notificationIcon from "../../assets/svg-icon/notification.svg";
import { readNotification } from "../../modules/Settings/store/service";

import { NotificationType } from "./types";

export default function Notifications({
  notifications,
}: {
  notifications: NotificationType[];
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const updateReadNotification = (
    notification: NotificationType & { index: number }
  ) => {
    dispatch(readNotification(notification));
  };

  const navigateToRoute = (notification: NotificationType, index: number) => {
    const readNotificationPayload = { ...notification, index };
    updateReadNotification(readNotificationPayload);
    switch (notification.type) {
      case "PAYMENT_LINK":
      case "STORE_FRONT":
        navigate(`/transactions/${notification.transactionId}`);
        break;
      case "WITHDRAWAL_ONCHAIN":
        if (notification.transactionId) {
          navigate(`/transfers/${notification.transactionId}`);
        }
        break;
      case "WITHDRAWAL_OFFCHAIN":
        if (notification.transactionId) {
          navigate(`/payouts/${notification.transactionId}`);
        }
        break;
      case "BROADCAST_MESSAGE":
        navigate(`/notifications/${notification.uuid}`, {
          state: notification,
        });
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu className="border-r-0 menu w-full right-0 top-5 bg-[#EEF0FF] p-4 pb-2 rounded-[1px]">
      {notifications.map((n, i: any) => {
        return (
          <Menu.Item
            key={i}
            onClick={() => navigateToRoute(n, i)}
            className="p-4 bg-white even:my-3 last-of-type:mb-0 last-of-type:mt-3"
          >
            <h2 className="text-sm font-medium">{n.title}</h2>
            {n.previewText && (
              <p className="text-[#02084B]/60 max-w-[60ch] truncate text-sm">
                {n.previewText}
              </p>
            )}
          </Menu.Item>
        );
      })}
      {notifications.length < 1 && (
        <p className="text-[#02084B]/60 p-5"> No Recent Notifications</p>
      )}
      <p
        onClick={() => navigate("/notifications")}
        className="text-right cursor-pointer hover:text-[#CC9933]/60 text-[#CC9933] pt-2"
      >
        See all notifications
      </p>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={["hover"]} placement="bottomLeft">
      <button>
        <img className="w-6" src={notificationIcon} alt="notfications" />
      </button>
    </Dropdown>
  );
}
