import { createAsyncThunk } from "@reduxjs/toolkit";
import { urlPath } from "../config/constants";
import { Requests } from "../httpRequest";
import { isAxiosError } from "../../components/Utils/helper";

const { paymentLink, businesses } = urlPath;

export const createPaymentLink = async (data: any) => {
  try {
    let res = await Requests.postFormData(`${paymentLink}`, data, true);
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const fetchPaymentLink = async (query: any) => {
  try {
    let res = await Requests.get(
      `${paymentLink}/${query.reference}/pay`,
      null,
      query
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const fetchAllPaymentLinks = async (query: any, id: string) => {
  try {
    let res = await Requests.get(
      `${businesses}/${id}/${paymentLink}`,
      null,
      query
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const createBusiness = createAsyncThunk(
  "create/business",
  async (data: any, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`${businesses}`, data, {});
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
