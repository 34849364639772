import { Button } from "antd";
import { ButtonHTMLType } from "antd/lib/button/button";
import { CSSProperties } from "react";
import React from "react";
interface IAddButtonProps {
  children: string | JSX.Element;
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  type?: ButtonHTMLType;
}

export default function AddButton({
  children,
  className = "",
  onClick,
  loading,
  disabled,
  type,
}: IAddButtonProps) {
  return (
    <Button
      size="large"
      className={`${className} ivry-add-button w-full h-10 md:text-sm text-xs p-2 text-white bg-[#2C3489] active:text-white rounded-md border-0 active:bg-[#2C3489] focus:text-white focus:bg-[#2C3489] hover:bg-[#2C3489]`}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      htmlType={type}
      //style={{ color: "white" }}
    >
      {children}
    </Button>
  );
}
