import { notification } from "antd";
import { ContentState, convertFromRaw } from "draft-js";
import moment from "moment";

export const IvoryPayUrl = process.env.REACT_APP_API_URL;

export const REQUEST_TIMEOUT = 60000;

export const INFINITE_STOCK = 1000;

export const STORAGE_KEYS = {
  USER_DETAILS_STORAGE_KEY: "ivorypay.user",
  CLIENT_TOKEN_STORAGE_KEY: "ivorypay.token",
};

export const ATTRIBUTE_LIST = ["Color", "Material", "Size", "Style", "Weight"];

export const urlPath = {
  auth: "auth",
  profile: "profile",
  transaction: "transactions",
  store: "stores",
  identities: "identities",
  kyc: "kyc",
  swaps: "swaps",
  businesses: "businesses",
  product: "products",
  notification: "notification",
  currency: "currencies",
  paymentLink: "payment-links",
  order: "orders",
  analytics: "analytics",
  payout: "payout-requests",
  invoice: "invoices",
  virtualAccounts: "virtual-accounts",
};

export const dayFormat = (date: Date = new Date(Date.now())): string => {
  return moment(date).format("D MMM Y");
};

export const mapToCountryCode = {
  ZA: "ZAR",
  KE: "KES",
  GH: "GHS",
  NG: "NGN",
};

export function getBlockStyle(block: any) {
  switch (block.getType()) {
    case "header-one":
      return "text-3xl";
    case "header-two":
      return "text-2xl";
    case "header-three":
      return "text-xl";
    case "header-four":
      return "text-lg";
    case "header-five":
      return "text-base";
    case "header-six":
      return "text-sm";
    default:
      return null;
  }
}

export const Currency = {
  USDC: "USDC",
  USDT: "USDT",
  //IVRY: "$IVRY",
  DAI: "DAI",
};

export const productImageUrl = (id: string) =>
  `${process.env.REACT_APP_IMAGE_BUCKET}/${id}`;

export const storeBackgroundColors = [
  "#02084B",
  "#001c55",
  "#084c61",
  "#2c6e49",
  "#590d22",
  "#450920",
  // "#f8f8ff",
];

export type globalExchangeRate = {
  USDCRate: number;
  USDTRate: number;
  SOLRate: number;
};

const broadcastMessageHeader = (title: string, createdAt: Date) =>
  `{"blocks":[{"key":"4a4v9","text":"${title}","type":"header-four","depth":0,"inlineStyleRanges":[{"offset":0,"length":20,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"ahd4l","text":"${dayFormat(
    createdAt
  )}","type":"header-six","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"3e83p","text":"","type":"header-six","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"7jvlk","text":"","type":"header-six","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;

interface IConstructBroadcastMessageHeader {
  title: string;
  createdAt: Date;
  body: string;
}

export const constructBroadcastMessageHeader = ({
  title,
  createdAt,
  body,
}: IConstructBroadcastMessageHeader) => {
  const parsedHeading = convertFromRaw(
    JSON.parse(broadcastMessageHeader(title, createdAt))
  );
  const parsedMessage = convertFromRaw(JSON.parse(body));
  const p = parsedHeading.getBlocksAsArray();
  const m = parsedMessage.getBlocksAsArray();
  const updatedBroadcast = [...p, ...m];
  const contentState = ContentState.createFromBlockArray(updatedBroadcast);
  return contentState;
};

export const dateFormat = (date: Date = new Date(Date.now())): string => {
  return moment(date).format("D MMM Y, h:mm a");
};

export const reportErrorMessage = (err: any, message?: string) => {
  let error = err?.message as string;
  if (error && error.toLowerCase() === "internal server error")
    error = "Something went wrong";

  notification.error({
    message: error || message || "Unable to perform action",
    description:
      err.errors && err.errors.map((details: string) => `${details}\n`),
  });
};

export function extractUniqueElements(
  arrayA: string[] = [],
  arrayB: string[] = []
): string[] {
  const uniqueElements = arrayA.filter((element) => !arrayB.includes(element));
  return uniqueElements;
}
