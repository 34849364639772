import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createBusiness } from "../../../app/services/global";
import {
  IBusinessType,
  IUserTypes,
  IWalletCoinType,
  IWalletType,
} from "../../../@types/common-type";
import { generatePublicIdentity } from "../../QRPayment/service";
import { updateUserProfile } from "../../Settings/store/service";
import {
  getBusiness,
  getProfile,
  getWalletBusiness,
  updateWalletStatus,
} from "./service";
export interface DashboardState {
  amount: number;
  wallet: IWalletCoinType;
  userProfile: IUserTypes | any;
  isLoading: boolean;
  loading: boolean;
  business: IBusinessType;
  isLoadingCurrency: boolean;
  showModal: {
    show: boolean;
    currency: string;
    status: "inactive" | "active";
  };
}

const initialState = {
  amount: 0,
  wallet: {
    SOL: {
      balance: 0,
      status: "inactive",
      usdEquivalent: 0,
    },
    USDC: {
      balance: 0,
      status: "active",
      usdEquivalent: 0,
    },
    USDT: {
      balance: 0,
      status: "active",
      usdEquivalent: 0,
    },
    NGN: {
      balance: 0,
      status: "active",
      usdEquivalent: 0,
    },
  },
  userProfile: {
    businesses: [],
  },
  isLoading: false,
  loading: false,
  isLoadingCurrency: false,
  business: {},
  showModal: {
    show: false,
    currency: "",
    status: "inactive",
  },
} as DashboardState;

const dashboardReducer = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    testMode: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
    showSolanaTerms: (state, { payload }) => {
      state.showModal = payload;
    },
    changeWalletStatus: (state, { payload }) => {
      const coin: "SOL" | "USDC" | "USDT" = payload.coin;
      state.wallet[coin].status = payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWalletBusiness.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.wallet = Object.assign(
        {},
        ...data.map((item: IWalletType) => ({
          [item.currency]: {
            balance: item.balance,
            status: item.status,
            name: item.currency,
            usdEquivalent: item.usdEquivalent,
          },
        }))
      );
    });

    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.userProfile = payload.data;
      state.loading = false;
    });

    builder.addCase(getProfile.pending, (state, { payload }) => {
      state.loading = true;
    });
    builder.addCase(getProfile.rejected, (state, { payload }) => {
      state.loading = false;
    });

    builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
      state.userProfile.countryId = payload.data.countryId || null;
      state.userProfile.phoneNumber = payload.data.phoneNumber;
      state.userProfile.email = payload.data.email;
      state.userProfile.developer = payload.data?.developer;
    });

    builder.addCase(generatePublicIdentity.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (state.business) state.business.publicId = payload.data.publicId;
    });
    builder.addCase(generatePublicIdentity.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(generatePublicIdentity.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(getBusiness.fulfilled, (state, { payload }) => {
      state.business = payload.data;
      state.isLoading = false;
    });
    builder.addCase(getBusiness.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBusiness.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateWalletStatus.fulfilled, (state) => {
      state.showModal.show = false;
    });
    builder.addCase(createBusiness.fulfilled, (state, { payload }) => {
      const { activeBusinessId: oldId, ...newBusiness } = payload.data;
      state.userProfile.businesses.push({
        uuid: oldId,
        ...newBusiness,
      });
    });
  },
});

export default dashboardReducer.reducer;
export const {
  testMode,
  showSolanaTerms,
  changeWalletStatus,
  //switchBusiness,
} = dashboardReducer.actions;
