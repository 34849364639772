import { Input, Spin } from "antd";

import { IvoryCustomInputProps } from "../../@types/input-types";

/**
 * Form Input component
 * @param {String} label
 * @param {String} type i.e "text","email"
 * @param {String} placeholder
 * @param {Boolean} hasValue i.e indicates in
 * @param {Function} onChange. {args:value}
 * @param {String} name
 * @returns react component
 **/

const CustomInput = ({
  type,
  placeholder = "",
  onChange,
  value,
  className = "",
  max,
  autoFocus = false,
  disabled = false,
  suffix,
  name,
  onPressEnter,
  id,
  prefix,
  loading,
  bordered,
  addonBefore,
  defaultValue,
  onBlur,
}: IvoryCustomInputProps) => {
  return (
    <div className="relative">
      {loading && (
        <Spin className="z-40 absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]" />
      )}
      <Input
        value={value ?? value}
        id={id ?? id}
        prefix={prefix ?? prefix}
        defaultValue={defaultValue ?? defaultValue}
        onPressEnter={onPressEnter ?? onPressEnter}
        type={type ?? type}
        className={`ivry-input ivry ${className} ${loading && "!opacity-50"}`}
        placeholder={placeholder}
        onChange={onChange ?? onChange}
        maxLength={max}
        autoComplete="off"
        bordered={bordered}
        autoFocus={autoFocus}
        onBlur={onBlur}
        addonBefore={addonBefore ?? addonBefore}
        name={name}
        disabled={disabled}
        suffix={suffix}
      />
    </div>
  );
};

export default CustomInput;
