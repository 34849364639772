import { createSlice } from "@reduxjs/toolkit";
import { switchActiveBusinessId } from "../../Dashboard/store/service";
import {
  registerUser,
  switchActiveNotification,
  userLogin,
  verifyToken,
  verifyTwoFactorAuthentication,
} from "./service";
import { createBusiness } from "../../../app/services/global";
import { ICountryCode } from "../../../@types/common-type";

export interface AuthState {
  auth: boolean;
  user: {
    uuid: string;
    firstName: string;
    activeNotification: boolean;
    lastName: string;
    type: string;
    email: string;
    activeBusiness: string;
    country: ICountryCode;
  };
  isSmsEnabled: boolean;
  isAuthEnabled: boolean;
  isLoading: boolean;
  verificationEmail: string;
  businessCurrency: any;
}
const initialState = {
  auth: false,
  user: {
    uuid: "",
    firstName: "",
    lastName: "",
    email: "",
    activeNotification: false,
    type: "",
    country: {
      uuid: "",
      dialCode: "",
      name: "",
      code: "NG",
      createdAt: "",
    },
  },
  isAuthEnabled: false,
  isSmsEnabled: false,
  verificationEmail: "",
} as AuthState;

const authReducer = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    userAuth: (state) => {
      state.auth = true;
    },
    destoryAuthUser: (state) => {
      state.auth = false;
      localStorage.clear();
    },
    emailToVerifyTwoFactor: (state, { payload }) => {
      state.verificationEmail = payload.email;
      state.isAuthEnabled = payload.google;
      state.isSmsEnabled = payload.sms;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.auth = true;
      state.user = data;
    });

    builder.addCase(registerUser.fulfilled, (state, { payload }) => {
      state.verificationEmail = payload.data.email;
    });
    builder.addCase(switchActiveNotification.fulfilled, (state) => {
      state.user.activeNotification = false;
    });
    builder.addCase(verifyToken.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.auth = true;
    });
    builder.addCase(verifyToken.rejected, (state, action) => {
      state.auth = false;
    });
    builder.addCase(
      verifyTwoFactorAuthentication.fulfilled,
      (state, action) => {
        state.user = action.payload.data;
        state.auth = true;
      }
    );
    builder.addCase(verifyTwoFactorAuthentication.rejected, (state, action) => {
      state.auth = false;
    });
    builder.addCase(switchActiveBusinessId.fulfilled, (state, { payload }) => {
      state.user.activeBusiness = payload.data.activeBusinessId;
    });

    builder.addCase(createBusiness.fulfilled, (state, { payload }) => {
      state.user.activeBusiness = payload.data.activeBusinessId;
      state.user.country.code = payload.data.primaryFiat;
    });
  },
});

export default authReducer.reducer;
export const { userAuth, destoryAuthUser, emailToVerifyTwoFactor } =
  authReducer.actions;
