import Icon from "@ant-design/icons";

const DownArrowSvg = () => {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82102 1.02051L4.9321 4.82491C4.69346 5.05836 4.30654 5.05836 4.0679 4.82491L0.178982 1.02051C-0.0596609 0.787056 -0.0596609 0.408549 0.178982 0.175092C0.417625 -0.0583646 0.804543 -0.0583646 1.04319 0.175092L4.5 3.55678L7.95681 0.175092C8.19546 -0.0583642 8.58237 -0.0583642 8.82102 0.175092C9.05966 0.408549 9.05966 0.787057 8.82102 1.02051Z"
        fill="#2C3489"
      />
    </svg>
  );
};

const DownArrowIcon = (props: any) => (
  <Icon component={DownArrowSvg} {...props} />
);

export default DownArrowIcon;
