import { createAsyncThunk } from "@reduxjs/toolkit";
import { urlPath } from "../../../app/config/constants";
import { Requests } from "../../../app/httpRequest";
import { isAxiosError } from "../../../components/Utils/helper";
import { CountryCodesType } from "../../../@types/types";

const { profile, businesses } = urlPath;
interface IFiatCurrency {
  from: string;
  to: string;
  amount: number;
}

export const getProfile = createAsyncThunk(
  "profile",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`${profile}`, null, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getAllBusiness = createAsyncThunk(
  "user/business",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`${profile}/businesses`, null, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getBusiness = createAsyncThunk(
  "business/current",
  async (id: any, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`businesses/${id}`, null, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw new Error("Unable to fetch business");
    }
  }
);

export const getSupportedCurrency = createAsyncThunk(
  "buisiness/support-currenccy",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await Requests.get(
        `businesses/${id}/supported-cryptocurrencies`,
        null,
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw new Error("Unable to fetch business");
    }
  }
);

export const getSupportedAllCurrency = createAsyncThunk(
  "buisiness/support-all-currenccy",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await Requests.get(
        `businesses/${id}/supported-currencies`,
        null,
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw new Error("Unable to fetch business");
    }
  }
);

export const getWalletBusiness = createAsyncThunk(
  "business/wallets",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`${profile}/wallets`, null, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const fetchFiatCurrency = async (query: IFiatCurrency) => {
  try {
    let res = await Requests.get(
      `exchange-rates/cryptofiat-conversion`,
      null,
      query
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const fetchConversionRate = async (query: IFiatCurrency) => {
  try {
    let res = await Requests.get(
      `exchange-rates/cryptofiat-normalized-conversion`,
      null,
      query
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const fetchBusinessActivityLogs = async (query: any) => {
  try {
    let res = await Requests.get(`${profile}/activity-logs`, null, query);
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const switchBusinessEnviroment = async (
  id: string,
  data: { environment: "LIVE" | "TEST" }
) => {
  try {
    let res = await Requests.patch(
      `${businesses}/${id}/environment`,
      data,
      null
    );
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const switchActiveBusinessId = createAsyncThunk(
  "switch/business",
  async (id: string, { rejectWithValue }) => {
    try {
      let res = await Requests.patch(
        `${profile}/active-business`,
        { businessId: id },
        null
      );
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const verifyGoogleAuthenticator = createAsyncThunk(
  "2FA/google-verify",
  async (data: { token: string }, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`2FA/google/verify`, data, null);
      if (res?.success) return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateWalletStatus = createAsyncThunk(
  "wallets/status",
  async ({ data, currency }: any, { rejectWithValue }) => {
    try {
      let res = await Requests.patch(`wallets/${currency}/status`, data, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const getNotification = createAsyncThunk(
  "business/notification",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.get(`/notification`, null, null);
      return res;
    } catch (err) {
      if (isAxiosError(err) && err.response)
        return rejectWithValue(err.response.data);
      throw err;
    }
  }
);

export const payoutFeeRate = async (code: CountryCodesType) => {
  try {
    const response = await Requests.get(`fees/${code}/payout`, null, null);
    return Promise.resolve(response);
  } catch (err) {
    if (isAxiosError(err) && err.response) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }
};
