import { createAsyncThunk } from "@reduxjs/toolkit";
import { urlPath } from "../../../app/config/constants";
import { Requests } from "../../../app/httpRequest";
import { isAxiosError } from "../../../components/Utils/helper";

const { businesses, identities } = urlPath;

export const getQRPayments = async (id: string, query: any) => {
  try {
    let res = await Requests.get(`${businesses}/${id}/transactions`, null, {
      ...query,
      type: "QR_PAY",
    });
    if (res?.success) return Promise.resolve(res);
  } catch (err) {
    if (isAxiosError(err) && err.response)
      return Promise.reject(err.response.data);
    return Promise.reject(err);
  }
};

export const generatePublicIdentity = createAsyncThunk(
  "business/publicId",
  async (_, { rejectWithValue }) => {
    try {
      let res = await Requests.post(`${identities}/business`, {}, null);
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
