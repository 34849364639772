import { Select } from "antd";
import React, { CSSProperties, ReactNode } from "react";

import DownArrowIcon from "../Icons/DownArrowIcon";
import DropdownIcon from "../Icons/DropdownIcon";

interface IvoryCustomSelectProps {
  placeholder?: string;
  onChange?: (t: any) => void;
  className?: string;
  value?: string | number;
  max?: number;
  disabled?: boolean;
  loading?: boolean;
  children: JSX.Element | JSX.Element[];
  allowClear?: boolean;
  defaultValue?: string | boolean;
  showSearch?: boolean;
  required?: boolean;
  style?: CSSProperties;
  prefixIcon?: ReactNode;
  downArrow?: boolean;
  id?: string;
}
const CustomSelect = ({
  placeholder = "",
  onChange,
  loading = false,
  disabled,
  children,
  prefixIcon,
  className = "",
  allowClear = false,
  //onFocus,
  style,
  downArrow = false,
  defaultValue,
  showSearch = false,
}: IvoryCustomSelectProps) => {
  let config = {};
  if (showSearch) {
    config = {
      optionFilterProp: "children",
      filterOption: (input: any, option: any) =>
        (option!.children as unknown as string).includes(input),
      filterSort: (optionA: any, optionB: any) =>
        (optionA!.children as unknown as string)
          .toLowerCase()
          .localeCompare(
            (optionB!.children as unknown as string).toLowerCase()
          ),
    };
  }

  return (
    <div className="relative">
      {prefixIcon && <div className="prefix-icon">{prefixIcon}</div>}
      <Select
        {...config}
        style={style}
        showSearch={showSearch ?? showSearch}
        className={`ivry-select ${className}`}
        loading={loading}
        disabled={disabled}
        defaultValue={defaultValue ?? defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        allowClear={allowClear}
        //onFocus={onFocus}
        suffixIcon={
          !downArrow ? (
            <DropdownIcon className="ant-select-suffix" />
          ) : (
            <DownArrowIcon />
          )
        }
      >
        {children}
      </Select>
    </div>
  );
};

export default CustomSelect;
