import Icon from "@ant-design/icons";

const ProfileSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12C4 12.1326 4.05268 12.2598 4.14645 12.3536C4.24021 12.4473 4.36739 12.5 4.5 12.5H13.293L10.646 15.146C10.5995 15.1924 10.5626 15.2475 10.5374 15.3082C10.5123 15.3689 10.4993 15.4339 10.4992 15.4996C10.4991 15.6323 10.5517 15.7596 10.6455 15.8535C10.7393 15.9474 10.8665 16.0002 10.9991 16.0003C11.1318 16.0004 11.2591 15.9478 11.353 15.854L14.853 12.354C14.9467 12.2602 14.9994 12.1331 14.9994 12.0005C14.9994 11.8679 14.9467 11.7408 14.853 11.647L11.353 8.147C11.2587 8.05592 11.1324 8.00552 11.0013 8.00666C10.8702 8.0078 10.7448 8.06039 10.6521 8.15309C10.5594 8.24579 10.5068 8.3712 10.5057 8.5023C10.5045 8.6334 10.5549 8.7597 10.646 8.854L13.293 11.5H4.5C4.36739 11.5 4.24021 11.5527 4.14645 11.6464C4.05268 11.7402 4 11.8674 4 12ZM17.5 2H6.5C5.83712 2.00053 5.20154 2.26409 4.73282 2.73282C4.26409 3.20154 4.00053 3.83712 4 4.5V8.5C4 8.63261 4.05268 8.75979 4.14645 8.85355C4.24021 8.94732 4.36739 9 4.5 9C4.63261 9 4.75979 8.94732 4.85355 8.85355C4.94732 8.75979 5 8.63261 5 8.5V4.5C5 4.10218 5.15804 3.72064 5.43934 3.43934C5.72064 3.15804 6.10218 3 6.5 3H17.5C17.8978 3 18.2794 3.15804 18.5607 3.43934C18.842 3.72064 19 4.10218 19 4.5V19.5C19 19.8978 18.842 20.2794 18.5607 20.5607C18.2794 20.842 17.8978 21 17.5 21H6.5C6.10218 21 5.72064 20.842 5.43934 20.5607C5.15804 20.2794 5 19.8978 5 19.5V15.5C5 15.3674 4.94732 15.2402 4.85355 15.1464C4.75979 15.0527 4.63261 15 4.5 15C4.36739 15 4.24021 15.0527 4.14645 15.1464C4.05268 15.2402 4 15.3674 4 15.5V19.5C4.00053 20.1629 4.26409 20.7985 4.73282 21.2672C5.20154 21.7359 5.83712 21.9995 6.5 22H17.5C18.1629 21.9995 18.7985 21.7359 19.2672 21.2672C19.7359 20.7985 19.9995 20.1629 20 19.5V4.5C19.9995 3.83712 19.7359 3.20154 19.2672 2.73282C18.7985 2.26409 18.1629 2.00053 17.5 2Z"
        fill="white"
      />
      <path
        d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
        stroke="#02084B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
        stroke="#02084B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Profile = (props: any) => <Icon component={ProfileSvg} {...props} />;

export default Profile;
