import { lazy } from "react";
import { RouteConfig } from "./types";

/**  function to lazy-load routes */
const loadModules = (link: string) =>
  lazy(() => import(`../../modules/${link}`));

export const routes: RouteConfig[] = [
  {
    path: "home",
    Component: loadModules("Dashboard/Dashboard"),
  },
  {
    path: "analytics",
    Component: loadModules("Analytics/Analytics"),
  },
  {
    path: "get-started",
    Component: loadModules("GetStarted/GetStarted"),
  },
  {
    path: "activity-logs",
    Component: loadModules("ActivityLogs/ActivityLogs"),
  },
  {
    path: "customers",
    Component: loadModules("Customer/Customer"),
  },
  {
    path: "customers/:id",
    Component: loadModules("Customer/CustomerTransaction"),
  },
  {
    path: "transfers",
    Component: loadModules("Transfer/Transfer"),
  },
  {
    path: "transfers/:id",
    Component: loadModules("Transfer/TransferDetail"),
  },
  {
    path: "transactions",
    Component: loadModules("Transaction/Transaction"),
  },
  {
    path: "transactions/:reference",
    Component: loadModules("Transaction/TransactionDetail"),
  },
  {
    path: "payouts",
    Component: loadModules("FiatPayout/FiatPayout"),
  },
  {
    path: "qr-pay",
    Component: loadModules("QRPayment/QrPayment"),
  },
  {
    path: "payouts/:id",
    Component: loadModules("FiatPayout/FiatPayoutDetail"),
  },
  {
    path: "swap",
    Component: loadModules("Swap/Swap"),
  },
  {
    path: "invoice",
    Component: loadModules("Invoice/Invoice"),
  },
  {
    path: "invoice/create",
    Component: loadModules("Invoice/CreateInvoice"),
  },
  {
    path: "invoice/:id",
    Component: loadModules("Invoice/InvoiceId"),
  },
  {
    path: "invoice/:id/edit",
    Component: loadModules("Invoice/CreateInvoice"),
  },
  {
    path: "ivory-storefront",
    Component: loadModules("Storefront/Storefront"),
  },
  {
    path: "ivory-storefront/store/:id",
    Component: loadModules("Storefront/StorefrontData"),
  },
  {
    path: "ivory-storefront/orders/:orderId",
    Component: loadModules("Storefront/OrderDetails"),
  },
  {
    path: "ivory-storefront/product/:productId",
    Component: loadModules("Storefront/Product/Product"),
  },

  {
    path: "ivory-storefront/storefrontdetails",
    Component: loadModules("Storefront/StorefrontDetails"),
  },
  {
    path: "payment-link",
    Component: loadModules("PaymentLink/PaymentLink"),
  },
  {
    path: "payment-link/:id",
    Component: loadModules("PaymentLink/PaymentTransaction"),
  },
  {
    path: "settings/",
    Component: loadModules("Settings/Settings"),
  },

  {
    path: "settings/verifybusiness",
    Component: loadModules("Settings/VerifyBusiness/VerifyBusiness"),
  },
  {
    path: "notifications",
    Component: loadModules("Notifications/Notifications"),
  },
  {
    path: "notifications/:id",
    Component: loadModules("Notifications/PreviewNotification"),
  },
  {
    path: "get-tokens",
    Component: loadModules("GetTokens/GetTokens"),
  },
  {
    path: "virtual-account",
    Component: loadModules("VirtualAccount/VirtualAccount"),
  },
  {
    path: "wallet",
    Component: loadModules("Wallet/Wallet"),
  },
];

export const authRoutes: RouteConfig[] = [
  {
    path: "login",
    Component: loadModules("Auth/Login"),
  },
  {
    path: "register",
    Component: loadModules("Auth/Register"),
  },
  {
    path: "otp",
    Component: loadModules("Auth/Otp"),
  },
  {
    path: "forgotpassword",
    Component: loadModules("Auth/ForgotPassword"),
  },
  {
    path: "verify",
    Component: loadModules("Auth/SendVerifyEmail"),
  },
  {
    path: "verification",
    Component: loadModules("Auth/VerifyEmail"),
  },
  {
    path: "password-reset",
    Component: loadModules("Auth/ResetPassword"),
  },
];
