import { Form, notification, Radio, Select } from "antd";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createBusiness } from "../../app/services/global";
import AddButton from "../Button/AddButton";
import CancelButton from "../Button/CancelButton";
import CustomInput from "../Input/CustomInput";
import CustomSelect from "../Input/CustomSelect";
import { eventTrack } from "../Utils/rudderstack";
import ModalShell from "./ModalShell";
import { ICountryCode } from "../../@types/common-type";

interface INewBusinessModal {
  show: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  total: number;
}

export default function NewBusinessModal({
  show,
  onCancel,
  total,
}: INewBusinessModal) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const countries = useAppSelector((state) => state.setting.countries);
  const dispatch = useAppDispatch();

  async function createBusinessAsync(values: any) {
    setLoading(true);
    values = {
      ...values,
      email: values.email.toLowerCase(),
    };

    const { countryId, type, name, email } = values;

    const findById = countries.find(
      (list: ICountryCode) => list.uuid === countryId
    );

    try {
      dispatch(createBusiness(values))
        .unwrap()
        .then((response) => {
          eventTrack("add_business_completed", {
            email: email,
            businessId: response?.data?.activeBusinessId,
            businessCountry: findById ? findById.name : "",
            totalBusiness: total,
            businessType: type,
            businessName: name,
          });
          onCancel();
        });
    } catch (err: any) {
      notification.error({
        message: (err as Error).message,
        description:
          err?.errors && err?.errors?.map((details: string) => details),
      });
    }
    setLoading(false);
  }

  return (
    <ModalShell
      title={<h3>Add a new business</h3>}
      show={show}
      cancel={onCancel}
      className="transfer-modal"
    >
      <Form
        form={form}
        onFinish={createBusinessAsync}
        name="newBusiness"
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item
          className="ivry-label"
          label="Business Name"
          name="name"
          id="businessName"
          rules={[
            {
              required: true,
              message: "Business name is Required",
            },
          ]}
        >
          <CustomInput type="text" />
        </Form.Item>
        <Form.Item
          className="ivry-label"
          label="Email Address"
          name="email"
          id="email"
          rules={[
            {
              type: "email",
              required: true,
            },
          ]}
        >
          <CustomInput type="email" />
        </Form.Item>
        <Form.Item
          className="ivry-label"
          label="Business country"
          name="countryId"
          id="countryId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <CustomSelect showSearch>
            {countries.map((country: ICountryCode, i) => (
              <Select.Option key={i} value={country.uuid}>
                {country.name}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <div className="my-4">
          <h2 className="font-semibold text-base text-[#02084B]">
            What type of business ?
          </h2>
          <div className="my-4 flex items-start">
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: "Select business type",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="starter">
                  {" "}
                  <div>
                    <h2 className="font-semibold text-base text-[#02084B]">
                      Starter Business
                    </h2>
                    <p className=" text-[#02084B] text-sm">
                      I’m testing my ideas with real customers, and preparing to
                      register my company
                    </p>
                  </div>
                </Radio>

                <Radio value="registered" className="mt-4">
                  {" "}
                  <div>
                    <h2 className="font-semibold text-base text-[#02084B]">
                      Registered Business
                    </h2>
                    <p className=" text-[#02084B] text-sm">
                      My business is approved, documented and licensed to
                      operate legally
                    </p>
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
        <div className="flex mt-4 mb-6 justify-between ">
          <CancelButton
            onClick={onCancel}
            className="w-max hover:!bg-[white] px-4 py-2"
          >
            Cancel
          </CancelButton>
          <AddButton
            className="bg-gradient-to-b 
            from-[#5C369B] to-[#2C3489] w-max px-4"
            loading={loading}
            type="submit"
          >
            Add Business
          </AddButton>
        </div>
      </Form>
    </ModalShell>
  );
}
