import Icon from "@ant-design/icons";

const CloseableSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="1.27246"
        width="1.75605"
        height="18.4385"
        rx="0.878025"
        transform="rotate(-45 0 1.27246)"
        fill="#02084B"
      />
      <rect
        x="13.1538"
        width="1.75605"
        height="18.4385"
        rx="0.878025"
        transform="rotate(45 13.1538 0)"
        fill="#02084B"
      />
    </svg>
  );
};

const CloseableIcon = (props: JSX.Element) => (
  <Icon component={CloseableSvg} {...props} />
);

export default CloseableIcon;
